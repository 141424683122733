/*.gallery{
  //column-count: 3;
  //column-gap: 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $md) {
    //column-count: 2;
  }
  @media (max-width: $sm) {
    //column-count: 1;
  }
  img{
    width: 33.3333%;
    height: auto;

    &:nth-of-type(4n){
     width: 66.66666%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
    &:nth-of-type(4n+1){
      width: 66.66666%;
      @media (max-width: $md) {
        width: 100%;
      }
    }
    &:nth-of-type(odd){
      //width: 33.333333%;
      @media (max-width: $md) {
        //width: 100%;
      }
    }
  }
}*/

.gallery{
  column-count: 2;
  column-gap: 2em;
  @media (max-width: $md) {
    //column-count: 2;
  }
  @media (max-width: $sm) {
    column-count: 1;
  }
  img{
    width: 100%;
    display: block;
    max-width: 80vh;
    height: auto;
    transition: all .5s;
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
  }
}