*, *::before, *::after{
  box-sizing: border-box;
}
html{
  margin: 0;
  min-width: 320px;
  height: 100%;
  //overflow: hidden;
  //word-break: break-all;
}
body{
  margin: 0;
  //min-height: 100%;
  display: flex;
  position: relative;
  height: 100%;
  overflow-x: hidden;
}
main{
  //min-height: 100%;
  //padding: calc(150px + 1em) 1em 0 1em;
  padding: calc(50px + 1em) 1em 0 1em;
  margin-left: 50px;
  position: relative;
  width: calc(100% - 50px);
  >.content{
    min-height: 54vh;
    >h1,
    >h2,
    >h3,
    >h4,
    >h5,
    >h6,
    >p,{
      margin-top: 0;
    }
  }

}
.post-type-archive-works,
.home{
  main{
    @media (max-width: $sm) {
      padding-top: 120px;
    }
  }
}
.clearfix:after {
	content: '';
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
}

.menu-social-menu-container{
  a{
    i{
      &:before{
        transition: all .5s;
        display: block;
      }
    }
    &:hover{
      i{
        &:before{
          transform: rotate(360deg);
          transition: all .5s;
        }
      }
    }
  }
}