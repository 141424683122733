.wysiwyg{
  max-width: 600px;
  min-width: 50%;
  >p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: 0;
  }
}