$ms-base: 10px;
$ms-ratio: $golden;
$ms-range: 1.5 480px, 2 1920px;

$xl: 1199px;
$lg: 991px;
$md: 767px;
$sm: 575px;

$black: #000;
$white: #fff;