header {
	position: fixed;
	left: 50px;
	right: 0;
	top: 0;
	//height: 150px;
	height: 50px;
	z-index: 2;
	background-color: $white;
	padding: 0 20px;
	transition: height .3s;
	//pointer-events: none;
	//border-bottom: 1px solid $black;
	@media (max-width: $sm) {
		padding: 0;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		border-bottom: 0;
	}

	.logo {
		padding: 10px 0;



		@media (max-width: $md) {
			margin: auto;
		}
		img {
			margin: auto;
			height: 80px;
			display: block;
			transition: height .3s;
			//pointer-events: all;
		}
	}

	&.scrolled{
		height: 90px;
		transition: height .3s;

		.logo {
			img{
				height: 20px;
				transition: height .3s;
			}
		}
		.breadcrumb{
			bottom: 0;
		}
	}
}

.cats {
	display: flex;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	padding-right: 20px;
	@media (max-width: $sm) {
		width: 100%;
		position: static;
		justify-content: flex-start;
	}
	li {
		list-style: none;
		line-height: 50px;
		height: 50px;
		a {
			cursor: pointer;
			padding: 0 10px;
			&.active {
				//font-style: italic;
				text-decoration: none;
			}
		}
	}
}

