footer{
  padding-top: 0;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 10px;
  border-top: 1px solid $black;
  ul{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    li{
      list-style: none;
      height: 50px;
      line-height: 50px;
      &.current-menu-item{
        a{
          //font-style: italic;
          text-decoration: none;
        }
      }
      @media (max-width: $sm) {
        display: block;
        width: 100%;
        text-align: center;
      }
      a{
        padding: 10px;
        color: $black;
      }
    }
  }
  .menu-social-menu-container{
    a{
      @include ms-respond(font-size, 2);
    }
  }
}
.legal{
  display: block;
  width: 100%;
  //height: 50px;
  text-align: center;
  margin-top: 0;
  //padding: 20px;
  height: 50px;
  p{
    margin-top: 0;
  }
  @media (max-width: $sm) {
    span{
      display: block;
      text-align: center;
    }
  }
}