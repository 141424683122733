.grid{
  column-count: 4;
  column-gap: 1em;
  @media (max-width: $lg) {
    column-count: 3;
  }
  @media (max-width: $md) {
    column-count: 2;
  }
  @media (max-width: $sm) {
    column-count: 1;
  }
  article{
    //border: 1px solid $black;
    min-height: 50px;
    background-color: #eee;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    &.hidden{
      display: none;
    }
    img{
      width: 100%;
      display: block;
      max-width: 100%;
      height: auto;
      transition: all .5s;
    }
    .content{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content:center;
      align-items:center;
      //opacity: 0;
      background-color: rgba(255,255,255,0.7);
      transition: all .5s;
      color: $black;
      display: none;
      padding: 10%;
    }
    &:hover{
      img {
        //transform: scale(1.1);
        transition: all .5s;
      }
      .content{
        //opacity: 1;
        transition: all .5s;
        display: flex;
      }
    }
  }
}