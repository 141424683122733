html{
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 700;
}
h1{
  @include ms-respond(font-size, 2);
}
h2{
  @include ms-respond(font-size, 1.8);
}
h3{
  @include ms-respond(font-size, 1.6);
}
h4{
  @include ms-respond(font-size, 1.5);
}
h5{
  @include ms-respond(font-size, 1.3);
}
h6{
  @include ms-respond(font-size, 1);
}
p{
  @include ms-respond(font-size, 1);
  font-weight: 400;
}
b{
  @include ms-respond(font-size, 1);
  font-weight: 800;
}
a{
  @include ms-respond(font-size, 1);
  text-decoration: underline;
  font-family: inherit;
  font-weight: inherit;
  color: #777;
  transition: all .3s;
  &:hover{
    color: #000;
    transition: all .3s;
  }
}
i{
  @include ms-respond(font-size, 1);
}
small{
  @include ms-respond(font-size, 0.7);
}
